import React from 'react';

import PropTypes from 'prop-types';

import { SponsoredText } from 'reaxl';

function AlphaShowcaseSponsoredText({
    uiContext = 'inventory',
    dealerName = '',
}) {
    const byDealerNameText = dealerName && `by ${dealerName}`;

    return (
        <>
            <div
                key="alphaShowcaseSponsoredText"
                className="margin-bottom-2 margin-top-5 margin-top-md-4 display-flex text-size-100 gap-1"
            >
                <SponsoredText />
                {uiContext === 'inventory' && byDealerNameText}
            </div>
            {uiContext === 'owner' && (
                <p
                    key="alphaShowcaseTitle"
                    className="row text-size-300 text-bold padding-bottom-3 margin-vertical-0"
                >
                    <span className="col-xs-12">
                        Featured Dealer Near You
                    </span>
                </p>
            )}
        </>
    );
}

AlphaShowcaseSponsoredText.propTypes = {
    /**
     * Notifies the AlphaShowcase whether to display owner or invertory Alpha listing
     */
    uiContext: PropTypes.oneOf([
        'owner',
        'inventory',
    ]),
    /**
     * The dealer name
     */
    dealerName: PropTypes.string,
};

export default AlphaShowcaseSponsoredText;
